// extracted by mini-css-extract-plugin
export var boxType = "Hero-module--box--type--ea492";
export var content = "Hero-module--content--96d22";
export var copy = "Hero-module--copy--e2b07";
export var hero = "Hero-module--hero--5b598";
export var hide = "Hero-module--hide--7661b";
export var image = "Hero-module--image--cd605";
export var layout = "Hero-module--layout--1a15e";
export var stackType = "Hero-module--stack--type--60154";
export var stretchType = "Hero-module--stretch--type--40b71";
export var tall = "Hero-module--tall--d7a7f";
export var video = "Hero-module--video--550a9";