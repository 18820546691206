import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import Hero from '@components/Hero/Hero';
import Columns from '@components/Layout/Columns/Columns';
import Content, { MotionContent } from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Accordion from '@components/Molecule/Accordion/Accordion';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const ProductCustomisation = ({ data }: { data: any }) => {
	return (
		<Page transparent={false}>
			<SEO
				title="Product customisation | UHV Design"
				description="Our customer support team are here to help. So please don't hesitate to get in touch for product support, advice or immediate assistance. Contact us."
			/>

			<Hero
				image={{
					src: getImage(data.hero),
					alt: 'Product Customisation',
				}}
				type="box">
				<Content>
					<MotionH level={2} as="h1">
						Product customisation
					</MotionH>

					<MotionCopy spacing="sm">
						For key accounts such as OEMs, Particle Accelerators and
						Government laboratories, we offer a product
						customisation service. By understanding our customer's
						technical, electrical and control requirements we can
						typically modify our standard products to make them
						easier to install, service and control without
						compromising reliability.
					</MotionCopy>
				</Content>

				<Content>
					<MotionCopy spacing="sm">
						Our in-house engineering team, production facility,
						clean room and dedicated test room allow us to design,
						manufacture, test and supply custom products to a
						diverse range of global customers.
					</MotionCopy>
				</Content>
			</Hero>

			<PageSection background="grey">
				<Columns stretch="latter">
					<MotionH level={3}>Product customisation</MotionH>

					<Grid>
						<MotionCopy>
							Our typical value-add engineering process is
							detailed here:
						</MotionCopy>

						<Accordion label="Understand the requirement">
							<Copy as="ul" spacing="none">
								<li>
									Work with our customer to understand the
									application challenges
								</li>
								<li>
									Determine the technical, commercial and
									practical requirements
								</li>
								<li>
									Provide technical and engineering
									consultancy
								</li>
							</Copy>
						</Accordion>

						<Accordion label="Design the solution">
							<Copy as="ul" spacing="none">
								<li>
									Our Engineering & Design team produce and
									share concepts, drawings and 3D models
								</li>
								<li>
									Online meetings are used to finalise the
									specification
								</li>
								<li>A drawing is provided for approval</li>
							</Copy>
						</Accordion>

						<Accordion label="Manufacture, supply and support">
							<Copy as="ul" spacing="none">
								<li>
									Our production team machine, clean, assemble
									and test
								</li>
								<li>
									Engineering & Sales provide ongoing
									technical support
								</li>
							</Copy>
						</Accordion>
					</Grid>
				</Columns>
			</PageSection>

			<PageSection>
				<Columns stretch="latter">
					<MotionH level={3}>Contact support</MotionH>

					<Grid type="fixed">
						<MotionCopy as="address">
							UHV DESIGN LTD
							<br />
							Acorn House
							<br />
							The Broyle
							<br />
							Ringmer
							<br />
							East Sussex
							<br />
							England, BN8 5NN
						</MotionCopy>

						<MotionContent>
							<a href="tel:+44 (0)1323 811188">
								T: +44 (0)1323 811188
							</a>

							<a href="mailto:sales@uhvdesign.com">
								E: sales@uhvdesign.com
							</a>
						</MotionContent>
					</Grid>
				</Columns>
			</PageSection>

			<PageSection background="blue">
				<Copy weight="bold" font="xl2" align="center" prose={true}>
					If you would like to discuss your project with our
					engineering team, please{' '}
					<Link to={LINKS.CONTACT} className="underline">
						contact us
					</Link>
					.
				</Copy>
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		hero: file(relativePath: { eq: "product-customisation/hero.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;

export default ProductCustomisation;
